import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../constants";
import { UproApiLinkAsuriteResponse } from "../types/UproApiLinkAsuriteResponse";
import { UproApiProfile } from "../types/UproApiProfile";
import { UproApiSaveCommunicationPreferencesRequest } from "../types/UproApiSaveCommunicationPreferencesRequest";
import { getAuthUser } from "../util/authUtil";

export const uproApi = createApi({
    reducerPath: "uproApi",
    tagTypes: ["Profile"],
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE_URL,
        prepareHeaders: headers => {
            headers.set("Authorization", `Bearer ${getAuthUser()?.id_token}`);

            // if there's a viewAs parameter, pass it along as an X-View-As header
            const viewAs = new URLSearchParams(window.location.search).get("viewAs");
            if (viewAs) {
                headers.set("X-View-As", viewAs);
            }

            return headers;
        },
    }),
    endpoints: builder => ({
        getProfile: builder.query<UproApiProfile, void>({
            query: () => "profile",
            providesTags: ["Profile"],
        }),

        linkAsurite: builder.mutation<UproApiLinkAsuriteResponse, string>({
            query: (serviceauthAccessToken: string) => {
                return {
                    url: "identity/linkAsurite",
                    method: "POST",
                    body: { serviceauthAccessToken },
                };
            },
            invalidatesTags: ["Profile"],
        }),

        saveCommunicationPreferences: builder.mutation<void, UproApiSaveCommunicationPreferencesRequest>({
            query: communicationPreferences => {
                return {
                    url: "preferences/communication",
                    method: "PUT",
                    body: communicationPreferences,
                };
            },
            invalidatesTags: ["Profile"],
        }),
    }),
});

export const { useGetProfileQuery, useLinkAsuriteMutation, useSaveCommunicationPreferencesMutation } = uproApi;
