import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { uproApi } from "../api/uproApi";
import communicationPreferencesSlice from "./communicationPreferencesSlice";

const store = configureStore({
    reducer: {
        [uproApi.reducerPath]: uproApi.reducer,
        [communicationPreferencesSlice.reducerPath]: communicationPreferencesSlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(uproApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export default store;
