import { Button } from "@asu/components-core";
import { User, UserManager } from "oidc-client-ts";
import { FunctionComponent } from "react";
import { useAuth } from "react-oidc-context";
import { useGetProfileQuery, useLinkAsuriteMutation } from "../api/uproApi";
import { SERVICEAUTH_CLIENT_ID, SERVICEAUTH_REDIRECT_URI, SERVICEAUTH_SCOPE } from "../constants";
import useNavigatePreserveQueryString from "../hooks/useNavigatePreserveQueryString.ts";

const AsuriteLinking: FunctionComponent = () => {
    const authProfile = useAuth().user?.profile;
    const { data: apiProfile, refetch: refetchProfile } = useGetProfileQuery();
    const [linkAsurite, { data: linkAsuriteData, error: linkAsuriteError }] = useLinkAsuriteMutation();
    const navigate = useNavigatePreserveQueryString();

    if (!authProfile || !apiProfile) {
        return null;
    }

    async function linkAccount() {
        const userManager = new UserManager({
            authority: "https://weblogin.asu.edu/serviceauth",
            metadata: {
                authorization_endpoint: "https://weblogin.asu.edu/serviceauth/oauth2/native/allow",
                token_endpoint: "https://weblogin.asu.edu/serviceauth/oauth2/native/token",
                code_challenge_methods_supported: ["S256"],
            },
            client_id: SERVICEAUTH_CLIENT_ID,
            redirect_uri: SERVICEAUTH_REDIRECT_URI,
            response_type: "code",
            scope: SERVICEAUTH_SCOPE,
        });

        const user = await shamefulServiceauthSignin(userManager);

        const response = await linkAsurite(user.access_token);
        if ("data" in response) {
            refetchProfile();
        }
    }

    const renderLinkingStatus = () => {
        if (linkAsuriteError) {
            return <div>🚨 Error linking ASURITE ID: {JSON.stringify(linkAsuriteError)}</div>;
        }

        if (linkAsuriteData) {
            return <div>✅ ASURITE ID ({linkAsuriteData.asurite}) linked successfully</div>;
        }

        return null;
    };

    const renderAsuriteStatus = () => {
        const asurite = authProfile["custom:asuriteid"] as string | undefined;
        if (asurite?.length) {
            return <div>✅ You are signed in with ASURITE ID: {asurite}</div>;
        }

        const linkedAsurite = apiProfile.eicProfile.user.asurite;
        if (linkedAsurite) {
            return <div>✅ You are signed in to an identity linked with ASURITE ID: {linkedAsurite}</div>;
        }

        const emailAssociatedAsurite = apiProfile.eicProfile.emailAssociatedUsers?.find(user => user.asurite)?.asurite;
        if (emailAssociatedAsurite) {
            return (
                <>
                    <p>
                        ℹ️ Your email address is associated with ASURITE ID {emailAssociatedAsurite}. Sign in to link
                        this account to your ASURITE ID.
                    </p>
                    <button onClick={() => linkAccount()}>Link Account</button>
                </>
            );
        }

        return (
            <>
                <p>ℹ️ Your identity is not associated with an ASURITE ID.</p>
                <p>
                    Sign in to link this account to your ASURITE ID.
                    <br />
                    <Button label="Link Account" onClick={() => linkAccount()} />
                </p>
                <p>
                    Or if you don't remember your login, resolve your identity
                    <br />
                    <Button label="Resolve Identity" onClick={() => navigate("/resolve-identity")} />
                </p>
            </>
        );
    };

    return (
        <>
            {renderLinkingStatus()}
            {renderAsuriteStatus()}
        </>
    );
};

async function shamefulServiceauthSignin(userManager: UserManager): Promise<User> {
    // OK, don't look at me like that. I know, this is gross, but the serviceauth token response doesn't follow the
    //    OAuth spec, and it returns the scope as a list rather than a space delimited string.
    //    This breaks oidc-client-ts library's parsing of the token response, so I'm monkey patching the Array prototype
    //    object to add a `.split(...)` method that will flatten the array as a space delimited string before string splitting it.
    //    I can feel your judgement, but I'm doing it anyway.
    //    If it's any consolation, I'm deleting the method after I'm done with it.

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Array.prototype.split = function (separator: string) {
        return this.join(" ").split(separator);
    };
    const user = await userManager.signinPopup();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete window.Array.prototype.split;

    return user;
}

export default AsuriteLinking;
