import { FunctionComponent } from "react";
import { useAuth } from "react-oidc-context";
import { useGetProfileQuery } from "../api/uproApi";
import { DplName, DplPerson, EicFbiUser } from "../types/UproApiProfile";
import AsuriteLinking from "./AsuriteLinking";

const ProfileSummary: FunctionComponent = () => {
    const authProfile = useAuth().user?.profile;
    const { data, error, isLoading } = useGetProfileQuery();

    const resolveName = (person?: DplPerson): DplName | undefined => {
        return person?.names?.["PRF"] ?? person?.names?.["PRI"];
    };

    return (
        <>
            <AsuriteLinking />
            <h3>OAuth Profile</h3>

            <p>ID: {authProfile?.sub}</p>
            <p>Username: {authProfile?.["cognito:username"] as string}</p>
            <p>Email: {authProfile?.email}</p>

            {isLoading && <div>Loading...</div>}
            {error && (
                <div>
                    Error... <pre>{JSON.stringify(error)}</pre>
                </div>
            )}

            {data && (
                <>
                    <h3>DPL Profile</h3>
                    {data.dplPrincipal && data.dplPrincipal.principalType === "primary" && (
                        <>
                            <p>Given Name: {resolveName(data.dplPrincipal.owner)?.givenName}</p>
                            <p>Middle Name: {resolveName(data.dplPrincipal.owner)?.middleName}</p>
                            <p>Family Name: {resolveName(data.dplPrincipal.owner)?.familyName}</p>
                        </>
                    )}
                    {data.dplPrincipal && data.dplPrincipal.principalType !== "primary" && (
                        <p>DPL principal ({data.dplPrincipal.principal}) is not the primary principal.</p>
                    )}
                    {!data.dplPrincipal && (
                        <p>
                            No data found for the given user in DPL. Link this user to an ASURITE to view user
                            information from DPL.
                        </p>
                    )}

                    <h3>EIC Profile</h3>
                    <h4>User</h4>
                    <EicFbiUserSummary user={data.eicProfile.user} />

                    <h4>Email Associated Users</h4>
                    {data.eicProfile.emailAssociatedUsers?.length ? (
                        <ul>
                            {data.eicProfile.emailAssociatedUsers.map((user, index) => (
                                <li key={index}>
                                    <EicFbiUserSummary user={user} />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No email associated users</p>
                    )}

                    <h4>Asurite Associated Users</h4>
                    {data.eicProfile.asuriteAssociatedUsers?.length ? (
                        <ul>
                            {data.eicProfile.asuriteAssociatedUsers.map((user, index) => (
                                <li key={index}>
                                    <EicFbiUserSummary user={user} />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No Asurite associated users</p>
                    )}

                    <h4>Raw Data</h4>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </>
            )}
        </>
    );
};

const EicFbiUserSummary: FunctionComponent<{ user: EicFbiUser }> = ({ user }) => {
    return (
        <div>
            <p>Sub: {user.sub}</p>
            <p>Username: {user.cognitoUsername}</p>
            <p>Email: {user.email.email}</p>
            <p>Email Verified: {user.email.verified ? "✅" : "❌"}</p>
            <p>IDP: {user.idp}</p>
            <p>Asurite: {user.asurite}</p>
            <p>Emplid: {user.emplid}</p>
            <p>Applications: {user.fbiApplications.join(", ")}</p>
        </div>
    );
};

export default ProfileSummary;
