import { Button } from "@asu/components-core";
import { FunctionComponent } from "react";

const ResolveIdentity: FunctionComponent = () => {
    return (
        <>
            <h2>Resolve Identity</h2>
            <div>
                <label htmlFor="resolve-identity-emplid">Emplid: </label>
                <input type="text" id="resolve-identity-emplid" />
            </div>
            <div>
                <label htmlFor="resolve-identity-ssn">Social Security Number: </label>
                <input type="password" id="resolve-identity-ssn" />
            </div>
            <div>
                <label htmlFor="resolve-identity-dob">Date of Birth: </label>
                <input type="date" id="resolve-identity-dob" />
            </div>
            <div>
                <Button label="Submit" />
            </div>
        </>
    );
};

export default ResolveIdentity;
