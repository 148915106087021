import { Button } from "@asu/components-core";
import React, { FunctionComponent, useEffect } from "react";
import { AuthProvider, AuthProviderProps, useAuth } from "react-oidc-context";
import { OIDC_AUTHORITY, OIDC_CLIENT_ID, OIDC_REDIRECT_URI } from "../constants";

const oidcConfig: AuthProviderProps = {
    authority: OIDC_AUTHORITY,
    client_id: OIDC_CLIENT_ID,
    redirect_uri: OIDC_REDIRECT_URI,
    onSigninCallback: () => {
        window.history.replaceState({}, "", window.location.pathname);
    },
};

export type AuthProps = React.PropsWithChildren;

const Auth: FunctionComponent<AuthProps> = props => {
    return (
        <AuthProvider {...oidcConfig}>
            <AuthHandler>{props.children}</AuthHandler>
        </AuthProvider>
    );
};

type AuthHandlerProps = React.PropsWithChildren;

const AuthHandler: FunctionComponent<AuthHandlerProps> = props => {
    const auth = useAuth();

    useEffect(() => {
        if (!auth.isLoading && !auth.isAuthenticated) {
            auth.signinRedirect();
        }
    }, [auth]);

    if (auth.isLoading) {
        return <>Loading...</>;
    }

    if (auth.error) {
        return <div>Auth error... {auth.error.message}</div>;
    }

    return (
        <>
            <div>{props.children}</div>
            <div>
                <Button label={"Sign Out"} onClick={() => auth.signoutSilent()} />
            </div>
        </>
    );
};

export default Auth;
