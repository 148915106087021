import React, { FunctionComponent } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import store from "../state/store";
import Auth from "./Auth";
import CommunicationPreferences from "./CommunicationPreferences";
import ProfileSummary from "./ProfileSummary";
import ResolveIdentity from "./ResolveIdentity";
import Root from "./Root";

const router = createBrowserRouter([
    {
        path: "*",
        element: <Root />,
        children: [
            { index: true, element: <ProfileSummary /> },
            { path: "resolve-identity", element: <ResolveIdentity /> },
            { path: "communication-preferences", element: <CommunicationPreferences /> },
        ],
    },
]);

const App: FunctionComponent = () => {
    return (
        <Auth>
            <ReduxProvider store={store}>
                <RouterProvider router={router} />
            </ReduxProvider>
        </Auth>
    );
};

export default App;
