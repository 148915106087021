import mobileLogo from "@asu/component-header/dist/assets/img/arizona-state-university-logo-vertical.png";
import desktopLogo from "@asu/component-header/dist/assets/img/arizona-state-university-logo.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ASUHeader } from "@asu/component-header/dist/asuHeader.es.js";
import { Button } from "@asu/components-core";
import { FunctionComponent } from "react";
import { useAuth } from "react-oidc-context";
import { Outlet } from "react-router-dom";
import "./Header.css";
import { OIDC_CLIENT_ID, OIDC_LOGOUT_REDIRECT_URI, OIDC_LOGOUT_URI } from "../constants.ts";
import useNavigatePreserveQueryString from "../hooks/useNavigatePreserveQueryString.ts";

const Root: FunctionComponent = () => {
    const navigate = useNavigatePreserveQueryString();
    const auth = useAuth();

    return (
        <>
            <div>
                <ASUHeader
                    title={"Universal Profile"}
                    site={"UPRO"}
                    logo={{
                        src: desktopLogo,
                        alt: "Arizona State University",
                        mobileSrc: mobileLogo,
                        brandLink: "https://www.asu.edu/",
                    }}
                    loggedIn={true}
                    userName={auth.user?.profile?.email ?? ""}
                    loginLink={""}
                    logoutLink={`${OIDC_LOGOUT_URI}?client_id=${OIDC_CLIENT_ID}&logout_uri=${encodeURIComponent(OIDC_LOGOUT_REDIRECT_URI)}`}
                    breakpoint={"Lg"}
                    animateTitle={false}
                    expandOnHover={false}
                    navTree={[{ id: 0, href: "/", text: "Home", type: "icon-home", selected: true }]}
                    mobileNavTree={[{ id: 0, href: "/", text: "Home", type: "icon-home", selected: true }]}
                    buttons={[]}
                    searchUrl="https://search.asu.edu/search"
                    isPartner={false}
                    partnerLogo={null as never}
                />
            </div>

            <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button label="Profile" onClick={() => navigate("/")} />
                    <Button label="Communication Preferences" onClick={() => navigate("/communication-preferences")} />
                </div>
                <Outlet />
            </div>
        </>
    );
};

export default Root;
