export const OIDC_AUTHORITY = process.env.REACT_APP_OIDC_AUTHORITY!;
export const OIDC_CLIENT_ID = process.env.REACT_APP_OIDC_CLIENT_ID!;
export const OIDC_REDIRECT_URI = process.env.REACT_APP_OIDC_REDIRECT_URI!;
export const OIDC_LOGOUT_URI = process.env.REACT_APP_OIDC_LOGOUT_URI!;
export const OIDC_LOGOUT_REDIRECT_URI = process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI!;

export const SERVICEAUTH_SCOPE = process.env.REACT_APP_SERVICEAUTH_SCOPE!;
export const SERVICEAUTH_CLIENT_ID = process.env.REACT_APP_SERVICEAUTH_CLIENT_ID!;
export const SERVICEAUTH_REDIRECT_URI = process.env.REACT_APP_SERVICEAUTH_REDIRECT_URI!;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL!;
