import { NavigateOptions, To, useNavigate } from "react-router-dom";

export default function useNavigatePreserveQueryString() {
    const navigate = useNavigate();
    return (to: To, options?: NavigateOptions) => {
        if (typeof to === "string") {
            navigate(
                {
                    pathname: to,
                    search: window.location.search,
                },
                options,
            );
        } else {
            const currentQuery = new URLSearchParams(window.location.search);
            const newQuery = new URLSearchParams(to.search);
            newQuery.forEach((value, key) => {
                currentQuery.set(key, value);
            });
            navigate({ ...to, search: "?" + currentQuery.toString() }, options);
        }
    };
}
